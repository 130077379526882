<template>
  <footer class="relative z-50 bg-blue-500 pt-12 text-white md:pt-24">
    <Container class="relative h-full min-h-[60vh] md:pb-32">
      <!-- MAIN FOOTER NAV -->
      <nav aria-label="Primäre Fusszeilen Navigation">
        <ul
          class="grid grid-cols-1 gap-x-2 gap-y-16 sm:grid-cols-2 md:grid-cols-3"
        >
          <li class="text-xl md:pr-2 md:text-4xl">
            Kontakt
            <ul class="pt-6 text-sm md:pt-10 md:text-base">
              <!-- ADDRESS -->
              <li class="mb-[1em]">
                <Component
                  :is="globals.address_link ? 'a' : 'address'"
                  :href="globals.address_link"
                  class="focus-default -ml-2 block w-fit whitespace-pre-line break-words px-2 not-italic ring-white hover:underline"
                >
                  {{ globals.address }}
                </Component>
              </li>
              <!-- PHONE -->
              <li>
                <a
                  class="focus-default -ml-2 px-2 ring-white hover:underline"
                  :href="`tel:${globals.phone}`"
                  >T {{ globals.phone }}</a
                >
              </li>
              <!-- MAIL -->
              <li>
                <a
                  class="focus-default -ml-2 px-2 ring-white hover:underline"
                  :href="`mailto:${globals.email}`"
                  >{{ globals.email }}</a
                >
              </li>
            </ul>
          </li>
          <li
            v-for="(col, i) in nav.tree"
            :key="i"
            class="text-xl md:pr-2 md:text-4xl"
          >
            {{ col.page.title }}
            <ul class="pt-6 text-sm md:pt-10 md:text-base">
              <li
                v-for="(navPoint, index) in col?.children"
                :key="index"
                class="mb-3 w-fit"
              >
                <NuxtLink
                  :to="navPoint.page.url"
                  class="focus-default -ml-2 inline-block h-fit px-2 ring-white hover:underline"
                >
                  {{ navPoint.page.title }}
                </NuxtLink>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <!-- BOTTOM FOOTER NAV -->
      <nav
        aria-label="Sekundäre Fusszeilen Navigation"
        class="bottom-0 right-0 flex w-full flex-col justify-between space-y-6 py-12 lg:absolute lg:flex-row lg:space-y-0 lg:px-[inherit]"
      >
        <ul class="text-xs md:flex md:space-x-6">
          <li v-for="(el, index) in meta.tree" :key="index">
            <NuxtLink
              v-if="el.page.url"
              :to="el.page.url"
              class="focus-default -ml-2 px-2 ring-white hover:underline"
              >{{ el.page.title }}</NuxtLink
            >
          </li>
        </ul>
        <!-- COPYRIGHT -->
        <div
          aria-label="hidden"
          class="focus-default -ml-2 px-2 ring-white hover:underline"
        >
          © Copyright {{ currentYear }} Landenhof
        </div>
      </nav>
    </Container>
  </footer>
</template>

<script setup>
const currentYear = new Date().getFullYear()
defineProps({
  nav: { type: Object, required: true },
  globals: { type: Object, required: true },
  meta: { type: Object, required: true },
})
</script>

<style>
.linkhover a {
  @apply hover:underline;
}
</style>
